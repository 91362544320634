
.travo {
    color: #33cc33;
    font-weight: bold;
    font-size: 24px;
    margin-left: 8px;
    text-decoration: none;
}

.nav-link{
    font-family: "Poppins", sans-serif;
}

.logo{
    width: 70px
}

.navbar-nav {
    width: 70%;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}


@media (max-width: 768px) { 
    ul.navbar-nav.mx-auto.mb-2.mb-lg-0 {
        flex-direction: column;
        align-items: center;
    }

    .travo{
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        margin-left: 8px;
        margin-top: 8px;
        text-decoration: none;
    }


.logo{
    width: 40px 
}
    
}


@media (max-width: 524px) { 

    .navbar-nav {
        flex-direction: column;
        align-items: center;

    }

    .travo{
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        margin-left: 8px;
        margin-top: 8px;
        text-decoration: none;
    }

    .logo{
        width: 40px  
    }

}