.main-container {
    display: flex;
    flex-direction: column;
    margin: 15vh 0vh 5vh 0vh;
    align-items: center;
    justify-content: center;
  }
  .img {
    width: 30vw;
    border-radius: 20px;
  }

  .Text {
    backdrop-filter: blur(10px);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    margin-right: 20px;
  }
  
  .containers {
    display: flex;
    max-width: 80vw;
    align-items: center;
  }
  
  .goal {
    display: flex;
    flex-direction: row;
    margin-top: 10vw;
    padding: 16px;
    column-gap: 2vw;
    justify-content: space-between;
  }
  
  @media (max-width: 768px) {
    .main-container {
      margin: 5vh 2vw 3vh 2vw;
    }

    .img {
        width: 60vw;
        border-radius: 20px;
        margin-bottom: 10px;
      }

      .Text {
        backdrop-filter: blur(10px);
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        margin-right: 20px;
      }
  
    .containers {
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 3%; /* Adjust margin as needed */
    }
  
    .goal {
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 8px; /* Adjust padding as needed */
    }
  }
  