.privacyPolicyMain{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.privacyPolicy{
    width: 80%;
    padding: 10%;
    margin: 40px 0px;
    color: black;
    background-color: rgb(255, 254, 251);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
}
.headingContainerPP{
    display: flex;
    justify-content: center;
    width: 100%;

}
.headingPP {
    margin-bottom: 40px;
    width: 300px;
    color: #00cc66;
    font-size: 40px;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.privacyPolicyContent{
    font-size: 18px;
}
.privacyPolicyLink {
    font-size: 18px;
}

@media (max-width: 768px){
    .headingPP {
        margin-bottom: 30px;
        font-size: 30px;
        width: 250px;
    }
    .privacyPolicy{
        width: 85%;
        padding: 7%;
        margin-top: 35px;
    }
    .privacyPolicyContent{
        font-size: 16px;
    }
    .privacyPolicyLink {
        font-size: 16px;
    }
}

@media (max-width: 500px){
    .headingPP {
        margin-bottom: 20px;
        font-size: 26px;
        width: 180px;
    }
    .privacyPolicy{
        width: 90%;
        padding: 5%;
        margin-top: 20px;
    }
    .privacyPolicyContent{
        font-size: 14px;
    }
    .privacyPolicyLink {
        font-size: 14px;
    }
}