.main {
    display: flex;
    height: 80vh;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {

    .main {
      display: contents;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .fonts {
      font-size: 14px; /* Corrected without quotes */
    }
  
    .image {
      max-width: 87%;
      margin-left: 6vw;
      height: auto;
    }
  }