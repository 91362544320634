.row{
  margin: 50px 0px;
}

.carousel-image {
  width: 100%; 
  max-width: 100%; 
  object-fit: stretch;
  }


  .carousel-item.active {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.carsoulBoxHeading {
  color: #000066;
  text-align: center;
}

.carsoulBoxContent {
  color: #00cc66;
}

.btn-custom {
    background-color: #33cc33;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 8px;
    text-decoration: none;
    margin-top: 22px;
}

.cardContainer {
  display: flex;
  flex-direction: row-reverse;
 
}


.carousel-item img {
  border-radius: 15px;
  width: 100%; /* Adjusted for responsiveness */
  height: auto; /* Ensures images maintain aspect ratio */
}

.carousel-description {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}



@media (max-width: 768px) {
  #row2{
    flex-direction: row-reverse;
  }
  .row{
    margin: 40px 0px;
    flex-direction: row-reverse;
  }
  .carousel-description{
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .carousel-description {
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  #row2{
    flex-direction: column;
  }

  .row{
    margin: 30px 0px;
    flex-direction: column-reverse;
  }

  .btn-custom {
    padding: 2.4vw;
    font-size: 14px;
  }

  .carousel-item img {
      border-radius: 15px;
      width: 60%;
      height: auto;
  }

  .cardContainer {
      margin-bottom: 6vw;
  }
}
