.adminPanel{
    display: flex;
    width: 90%;
    min-height: 80vh;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: center;
}

.adminPanelHeading h2 {
    color: black;
    width: 250px; 
}

.adminPanelHeading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 4vw;
}

.logOutBalanceButton{
    width: 75px;
}

.logOutButton {
    align-self: flex-start;
    background-color: red;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.cardContainer {
    margin: 20px ;
    flex-direction: row;
}

.cards {
    height: 300px;
    width: 200px;
    margin: 10px;
    padding: 15px;
    border-radius: 20px;
    color: black;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.cardTitle{
    font-size: 30px; 
    margin-bottom: 15px;
}

.cardContent {
    font-size: 18px; 
    margin-bottom: 15px;
}

.cardSubContent {
    font-size: 15px; 
}


@media(min-width: 800px)and (min-height: 1000px){
    .cards {
        height: 400px;
        width: 300px;
    }
    
    .cardTitle{
        font-size: 40px; 
    }
    .cardContent {
        font-size: 20px; 
    }
    .cardSubContent {
        font-size: 17px; 
    }
}

@media (max-width: 500px) {
    .adminPanel{
        width: 100%;
    }
    .cardContainer{
        flex-direction: column;
    }
    .adminPanelHeading{
        width: 96%;
    }
    .adminPanelHeading h2 {
        width: 200px; 
    }
    .logOutBalanceButton{
        width: 60px;
    }
    .logOutButton {
        padding: 3px;
        font-size: 14px;
    }
    .cardTitle{
        font-size: 25px; 
        margin-bottom: 15px;
    }
    
    .cardContent {
        font-size: 15px; 
        margin-bottom: 15px;
    }
    
    .cardSubContent {
        font-size: 13px; 
    }
    .cards {
        height: 250px;
    }
}