.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.background-image {
  background-image: url('../src/assets/Images/mackBook2.png');
  background-size: cover;
  background-position: center;
  background-color: 'rgba(255, 255, 255, 0.5)';
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  backdrop-filter: 'blur(10px)';
  top: 0;
  left: 0;
}



.main-content {
  /* position: relative; */
  z-index: 1; /* Ensure content is above the background */
  /* width: fit-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Ensure text is readable */
  font-family: "Poppins", sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .background-image {
  background-image: url('../src/assets/Images/mackBook2.png');
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
} */

/* Tablet and larger screens */
@media (min-width: 768px) {
  .background-image {
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .background-image {
    background-size: cover;
    background-position: center;
    /* overflow-x: hidden; */
  }

  .main-content {
    width: fit-content;
    display: contents;
  }
}

@media (max-width: 524px) {
  .background-image {
    background-size: cover;
    background-position: center;
  }

  .main-content {
    width: fit-content;
    display: contents;
  }
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
   .background-image {
    background-size: auto 100%; /* Adjust as needed */
  } 
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .background-image {
    background-size: cover;
    background-position: center;
  }

}

/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 1440px) {
  .background-image {
    background-size: cover; /* Adjust as needed */
  }
}
