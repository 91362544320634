.vendorTable {
    width: 90%;
    min-height: 80vh;
}

.vendorTableTitle {
    display: flex;
    cursor:default;
}
.backArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33cc33;
    border-radius: 50%;
    font-size: 30px;
    height: 40px;
    width: 40px;
}
.arrow{
    color: black;
    text-decoration: none;
}

.vendorTableTitle h2 {
    margin-left: 1.5vw;
    color: black;
}

.vendorCountBox {
    display: flex;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid black;
    border-radius: 20px;
    color: black;
    margin-top: 10px;
}


.vendorCountSubBox {
    display: flex;
    border: 2px solid black;
    background-color: #94bb86;
    border-radius: 10px;
    margin: 10px;
    flex: 1;
}
/* .vendorCountCardsTotal {
    background-color: #ffff66;
    text-align: center;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
} */
.vendorCountCards {
    text-align: center;
    width: 50%;
    padding: 5px;
    cursor: pointer;
}
.vendorCountCardsActive {
    text-align: center;
    width: 50%;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(109, 219, 109);
    cursor: pointer;
}

.vendorType {
    font-size: 20px;
    font-weight: 500;
}

.vendorCount {
    font-size: 30px;
}

.vendorDataTable {
    margin-top: 20px;
    background-color: rgb(243, 243, 243);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vendorDataTableHeadingBlock {
    display: flex;
    padding: 8px 5px 5px 5px;
    background-color: #33cc33;
    cursor:default;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);

}
.vendorDataTableContentBlock {
    color: black;
    padding: 5px 5px 10px 5px;
}

.vendorDataTableHeadingSrNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.vendorDataTableHeadingName{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    flex: 1;
}
.vendorDataTableHeadingAadharCard{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.vendorDataTableHeadingMobileNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
}
/* .vendorDataTableHeadingActive{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
} */
.vendorDataTableHeadingCustID{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.vendorDataTableHeadingVerified{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.vendorDataTableHeadingDone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}


.vendorDataTableSrNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.vendorDataTableName{
    display: flex;
    align-items: center;
    padding: 2px 2px 2px 8px;
    flex: 1;
    border: 1px solid black;
}
.vendorDataTableAadharCard{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.vendorDataTableMobileNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
    border: 1px solid black;
}
/* .vendorDataTableActive{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
} */
.vendorDataTableCustID{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.vendorDataTableVerified{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.vendorDataTableDone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
} 

/* .vendorDataTableIsActiveCheckBox {
    cursor: pointer;
} */
.vendorDataTableIsVerifiedCheckBox {
    cursor: pointer;
}
.vendorDataTableDoneButton{
    background-color: #00cc66;
    width: 65px;
    height: 30px;
    border-style: none;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .vendorTable {
        width: 100%;
    }
    .backArrow{
        font-size: 25px;
        height: 30px;
        width: 30px;
        margin-left: 20px;
    }
    .vendorCountBox {
        flex-direction: column;
        width: 90%;
        margin: 20px;
    }
    .vendorType {
        font-size: 15px;
        font-weight: 500;
    }
    .vendorCount {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .backArrow{
        font-size: 20px;
        height: 24px;
        width: 24px;
        margin-left: 15px;
    }
    .vendorTable {
        width: 100%;
    }
    .vendorDataTableHeadingBlock{
        font-size: 12px;
    }
    .vendorDataTable {
        font-size: 10px;
    }
    #activeVendors{
        display: none;
    }
    
    .vendorDataTableHeadingSrNo{
        display: none;
    }
    .vendorDataTableHeadingAadharCard{
        width: 70px;
    }
    .vendorDataTableHeadingMobileNo{
        width: 60px;
    }
    /* .vendorDataTableHeadingActive{
        display: none;
    } */
    .vendorDataTableHeadingCustID{
        width: 40px;
    }
    .vendorDataTableHeadingVerified{
        width: 40px;
    }
    .vendorDataTableHeadingDone{
        width: 35px;
    }

    .vendorDataTableSrNo{
        display: none;
    }
    .vendorDataTableName{
        border: 0.5px solid black;
    }
    .vendorDataTableAadharCard{
        width: 35px;
        border: 0.5px solid black;
    }
    .vendorDataTableMobileNo{
        width: 70px;
        border: 0.5px solid black;
    }
    /* .vendorDataTableActive{
        display: none;
    } */
    .vendorDataTableCustID{
        width: 40px;
    }
    .vendorDataTableVerified{
        width: 40px;
        border: 0.5px solid black;
    }
    .vendorDataTableDone{
        padding: 1px;
        width: 35px;
        border: 0.5px solid black;
    } 

    .vendorDataTableDoneButton{
        font-size: 8px;
        width: 33px;
        height: 20px;
    }
}