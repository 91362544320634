.customerTable {
    width: 90%;
    min-height: 80vh;
}

.customerTableTitle {
    display: flex;
    cursor:default;
}
.backArrow{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 30px;
    height: 40px;
    width: 40px;
}
.arrow{
    height: 80%;
    width: 80%;
    color: black;
    text-decoration: none;
}

.customerTableTitle h2 {
    padding-left: 1.5vw;
    color: black;
}

.customerCountBox {
    display: flex;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid black;
    border-radius: 20px;
    color: black;
    margin-top: 10px;
}


.customerCountSubBox {
    display: flex;
    border: 2px solid black;
    background-color: #94bb86;
    border-radius: 10px;
    margin: 10px;
    flex: 1;
}
.customerCountCardsTotal {
    background-color: #ffff66;
    text-align: center;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.customerCountCards {
    text-align: center;
    width: 50%;
    padding: 5px;
    cursor: pointer;
}
.customerCountCardsActive {
    text-align: center;
    width: 50%;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(109, 219, 109);
    cursor: pointer;
}

.customerType {
    font-size: 20px;
    font-weight: 500;
}

.customerCount {
    font-size: 30px;
}

.customerDataTable {
    margin: 20px 0px 30px 0px;
    background-color: rgb(243, 243, 243);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customerDataTableHeadingBlock {
    display: flex;
    padding: 8px 5px 5px 5px;
    background-color: #33cc33;
    cursor:default;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);

}
.customerDataTableContentBlock {
    color: black;
    padding: 5px 5px 10px 5px;
}

.customerDataTableHeadingSrNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.customerDataTableHeadingName{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    flex: 1;
}
.customerDataTableHeadingDate{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
}
.customerDataTableHeadingMobileNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
}
.customerDataTableHeadingRideDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
}
.customerDataTableHeadingPrice{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.customerDataTableHeadingIsActive{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.customerDataTableHeadingRefund{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}
.customerDataTableHeadingDone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
}


.customerDataTableSrNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.customerDataTableName{
    display: flex;
    align-items: center;
    padding: 2px 2px 2px 8px;
    flex: 1;
    border: 1px solid black;
}
.customerDataTableDate{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
    border: 1px solid black;
}
.customerDataTableMobileNo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
    border: 1px solid black;
}
.customerDataTableRideDetails{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 120px;
    border: 1px solid black;
}
.customerDataTablePrice{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.customerDataTableIsActive{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.customerDataTableIsVerified{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
}
.customerDataTableDone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 70px;
    border: 1px solid black;
} 

.customerDataTableIsActiveCheckBox {
    cursor: pointer;
}
.customerDataTableIsVerifiedCheckBox {
    cursor: pointer;
}
.customerDataTableDoneButton{
    background-color: #00cc66;
    width: 65px;
    height: 30px;
    border-style: none;
    border-radius: 5px;
}

.customerDetailsMain {
    display: flex;
    flex-direction: row;
    background-color: lightgray;
    margin: 4px 0px ;
    padding: 0px 10px;
}
.customerDetails {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    background-color: lightgray;
    margin-top: 10px ;
    padding: 0px 10px;
}
.customerDetailsCardOne{
    flex : 1;
    border-right: 2px solid black;
}
.customerDetailsCardTwo{
    flex : 1;
    padding-left: 10px;
}
.customerDataTableRefundButton {
    background-color: #00cc66;
    width: 100px;
    height: 30px;
    border-style: none;
    border-radius: 5px;
    margin: 0px 5px;
}



@media (max-width: 768px) {
    .customerTable {
        width: 100%;
    }
    .backArrow{
        font-size: 25px;
        height: 30px;
        width: 30px;
        margin-left: 20px;
    }
    .customerCountBox {
        flex-direction: column;
        width: 90%;
        margin: 20px;
    }
    .customerType {
        font-size: 15px;
        font-weight: 500;
    }
    .customerCount {
        font-size: 20px;
    }
    .customerDetails {
        flex-direction: column;
    }
    .customerDetailsCardOne{
        border: none;
    }
    .customerDetailsCardTwo{
        padding-left: 0px;
        padding-bottom : 10px;
    }
    
}

@media (max-width: 600px) {
    .backArrow{
        font-size: 20px;
        height: 24px;
        width: 24px;
        margin-left: 15px;
    }
    .customerTable {
        width: 100%;
    }
    .customerDataTableHeadingBlock{
        font-size: 12px;
    }
    .customerDataTable {
        font-size: 10px;
    }
    #activeCustomers{
        display: none;
    }
    
    .customerDataTableHeadingSrNo{
        display: none;
    }
    .customerDataTableHeadingDate{
        width: 60px;
    }
    .customerDataTableHeadingMobileNo{
        width: 60px;
    }
    .customerDataTableHeadingRideDetails{
        width: 60px;
    }
    .customerDataTableHeadingPrice{
        width: 40px;
    }
    .customerDataTableHeadingIsActive{
        display: none;
    }
    .customerDataTableHeadingRefund{
        width: 40px;
    }
    .customerDataTableHeadingDone{
        width: 35px;
    }

    .customerDataTableSrNo{
        display: none;
    }
    .customerDataTableName{
        border: 0.5px solid black;
    }
    .customerDataTableDate{
        width: 60px;
        border: 0.5px solid black;
    }
    .customerDataTableMobileNo{
        width: 60px;
        border: 0.5px solid black;
    }
    .customerDataTableRideDetails{
        width: 60px;
        border: 0.5px solid black;
    }
    .customerDataTableIsActive{
        display: none;
    }
    .customerDataTablePrice{
        width: 40px;
        border: 0.5px solid black;
    }
    .customerDataTableIsVerified{
        width: 40px;
        border: 0.5px solid black;
    }
    .customerDataTableDone{
        padding: 1px;
        width: 35px;
        border: 0.5px solid black;
    } 

    .customerDataTableDoneButton{
        font-size: 8px;
        width: 33px;
        height: 20px;
    }
    .customerDataTableRefundButton {
        width: 80px;
        height: 24px;
    }
    .customerDetails p {
        font-size: 12px;
        margin: 5px 0px;
    }
}