.logInMain {
    /* width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    display: grid;
    justify-content: flex-end;
    align-items: center; */
    display: flex;
    width: 100%;
    min-height: 80vh;
    margin: 30px 0px;
    justify-content: center;
    align-items: center;
}

.logIn {
    width: 400px;
    height: 400px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 50px;
    margin: 80px;
}

.logInForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logInForm h2{
    color: #000066;
    text-align: left;
}

.logInInput {
    width: 100%;
}

.logInName, .logInPass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 2px solid black;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 5px ;
    flex-direction: row;
    padding-left: 5px;
}

.logInInputName {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    border: none;
    outline: none;
}
.logInInputPass {
    height: 100%;
    width: 85%;
    border-radius: inherit;
    border: none;
    outline: none;
}
.showPassword {
    color: black;
    font-size: 14px;
    width: 15%;
    cursor: pointer;
}

.logInSubmit {
    display: flex;
    width: 100%;
    justify-content: center;
}
.logInButton {
    background-color: #00cc66;
    padding: 10px 100px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .logInMain{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .logIn {
        width: 450px;
        height: 400px;
    }

    .logInForm h2{
        font-size: 30px;
    }
    
}

@media (max-width: 500px) {
    .logInMain{
        width: 100%;
    }
    .logIn {
        width: 85%;
        height: 350px;
        padding: 15px;
        margin: 0px;
    }
    .logInForm {
        height: 100%;
        width: 100%;
    }
    .showPassword {
        font-size: 12px;
    }
    .logInButton {
        padding: 10px 40px;
    }
    
}