.imageCarouselMain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}


@media(min-width: 1500px)and (min-height: 1000px){
    .imageCarouselMain {
        width: 50%;
    }
}
@media(min-width: 700px)and (min-height: 1000px){
    .imageCarouselMain {
        width: 75%;
    }
}

@media(max-width: 700px){
    .imageCarouselMain {
        width: 80%;
    }
}
@media(max-width: 500px){
    .imageCarouselMain {
        width: 100%;
    }
}